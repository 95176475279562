/* eslint-disable no-use-before-define */
import { Theme } from '@mui/material';
import { red } from '@mui/material/colors';
import { createTheme, PaletteColorOptions } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    primaryDark: Palette['primary'];
    statePrimaryOverlay: Palette['primary'];
    stateWhiteOverlay: Palette['primary'];
    errorDark: Palette['error'];
    primaryV2: Palette['primary'];
    secondaryV2: Palette['secondary'];
    infoV2: Palette['info'];
    errorV2: Palette['error'];
    textV2: Palette['text'];
    primaryCPD: Palette['primary'];
    secondaryCPD: Palette['secondary'];
    gradientBgRetail: Palette['background'];
    textOnPrimary: Palette['text'];
  }
  interface PaletteOptions {
    primaryDark: PaletteOptions['primary'];
    errorDark: PaletteOptions['error'];
    primaryV2: PaletteOptions['primary'];
    secondaryV2: PaletteOptions['secondary'];
    infoV2: PaletteOptions['info'];
    errorV2: PaletteOptions['error'];
    textV2: PaletteOptions['text'];
    primaryCPD: PaletteOptions['primary'];
    secondaryCPD: PaletteOptions['secondary'];
    gradientBgRetail: PaletteOptions['background'];
    textOnPrimary: Palette['text'];
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h4lanna: React.CSSProperties;
    h5lanna: React.CSSProperties;
    h5: React.CSSProperties;
    h6lanna: React.CSSProperties;
    h7: React.CSSProperties;
    h7lanna: React.CSSProperties;
    h8: React.CSSProperties;
    h9: React.CSSProperties;
    h9lanna: React.CSSProperties;
    h10: React.CSSProperties;
    h11: React.CSSProperties;
    h12: React.CSSProperties;
    h13: React.CSSProperties;
    body1lanna: React.CSSProperties;
    button_deprecated: React.CSSProperties;
    helperText: React.CSSProperties;
    HeaderTooltips: React.CSSProperties;
    MobileMiniDescription: React.CSSProperties;
    BaseLegacy: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h3v2: React.CSSProperties;
    h4lanna?: React.CSSProperties;
    h5_deprecated?: React.CSSProperties;
    h5lanna_deprecated?: React.CSSProperties;
    h5lanna?: React.CSSProperties;
    h5?: React.CSSProperties;
    h6_deprecated?: React.CSSProperties;
    h6lanna_deprecated?: React.CSSProperties;
    h6lanna?: React.CSSProperties;
    h7?: React.CSSProperties;
    h7lanna?: React.CSSProperties;
    h8?: React.CSSProperties;
    h9?: React.CSSProperties;
    h9lanna?: React.CSSProperties;
    h10?: React.CSSProperties;
    h11?: React.CSSProperties;
    h12?: React.CSSProperties;
    h13?: React.CSSProperties;
    body1lanna?: React.CSSProperties;
    body2lanna?: React.CSSProperties;
    body2_deprecated?: React.CSSProperties;
    button_deprecated?: React.CSSProperties;
    helperText: React.CSSProperties;
    HeaderTooltips: React.CSSProperties;
    MobileMiniDescription: React.CSSProperties;
    BaseLegacy: React.CSSProperties;
    tagTextBold: React.CSSProperties;
    textLink: React.CSSProperties;
    tooltipsText: React.CSSProperties;
    textExtraLarge: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h3v2: true;
    h4lanna: true;
    h5_deprecated: true;
    h5lanna_deprecated: true;
    h5lanna: true;
    h5: true;
    h6_deprecated: true;
    h6lanna_deprecated: true;
    h6lanna: true;
    h7: true;
    h7lanna: true;
    h8: true;
    h9: true;
    h9lanna: true;
    h10: true;
    h11: true;
    h12: true;
    h13: true;
    body1lanna: true;
    body2lanna: true;
    body2_deprecated: true;
    button_deprecated: true;
    helperText: true;
    BaseLegacy: true;
    tagTextBold: true;
    textLink: true;
    tooltipsText: true;
    textExtraLarge: true;
    HeaderTooltips: true;
    MobileMiniDescription: true;
  }
}

declare module '@mui/material/styles' {
  interface PaletteColor {
    pressed?: string;
    hover?: string;
    selected?: string;
    disabled?: string;
  }
  interface SimplePaletteColorOptions {
    pressed?: string;
    hover?: string;
    selected?: string;
    disabled?: string;
  }
  interface CustomPalette {
    primaryCPD: PaletteColorOptions;
    statePrimaryOverlay: PaletteColorOptions;
    stateWhiteOverlay: PaletteColorOptions;
    secondaryCPD: PaletteColorOptions;
    commonText: PaletteOptions['text'];
    backgroundSuccess: PaletteOptions['text'];
    border: PaletteOptions['text'];
    borderSuccess: PaletteOptions['text'];
    uploadLegacy: PaletteOptions['text'];
    icon: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primaryCPD: true;
    primary: true;
    secondaryCPD: true;
  }
}

// TODO: Refactor remove withTheme() from styled
// declare module 'styled-components' {
//   export interface DefaultTheme extends Theme { }
// }

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: 'Heavent',
    fontSize: 18,
    h1: {
      fontWeight: 'bold',
      fontSize: 36,
      lineHeight: '43px',
    },
    h2: {
      fontSize: 36,
    },
    /**
     * TODO: recheck with Design System and other render UI
     * Ref >>> https://www.figma.com/design/HwDzgmOkDcKsjdI2VlpAg6/B2C_DesignSystem?node-id=16-898&t=HnjdWhaTlXmVg4cS-0
     */
    // h3: {
    //   fontWeight: 'bold',
    //   fontSize: 30,
    //   lineHeight: '34px',
    // },
    h3: {
      fontWeight: 'bold',
      fontSize: 24,
    },
    h3v2: {
      fontWeight: 'bold',
      fontSize: 30,
      lineHeight: '34px',
    },
    h4: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '24px',
    },
    h4lanna: {
      fontSize: 24,
      fontFamily: 'Lanna',
    },
    h5: {
      fontWeight: 500,
      fontSize: 22,
      lineHeight: '24px',
    },
    h5_deprecated: {
      fontWeight: 'bold',
      fontSize: 22,
      lineHeight: '24px',
    },
    h5lanna_deprecated: {
      fontSize: 22,
      fontWeight: 'bold',
      lineHeight: '24px',
      fontFamily: 'Lanna',
    },
    h5lanna: {
      fontSize: 22,
      fontWeight: 500,
      lineHeight: '24px',
      fontFamily: 'Lanna',
    },
    h6: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '22px',
    },
    h6lanna: {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: 'Lanna',
    },
    h6_deprecated: {
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: '22px',
    },
    h6lanna_deprecated: {
      fontSize: 18,
      fontWeight: 'bold',
      fontFamily: 'Lanna',
    },
    h7: {
      fontSize: 18,
      lineHeight: '22px',
    },
    h7lanna: {
      fontSize: 18,
      fontFamily: 'Lanna',
      fontWeight: 'normal',
    },
    h8: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    h9: {
      fontSize: 16,
    },
    h9lanna: {
      fontSize: 16,
      fontFamily: 'Lanna',
    },
    h10: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    h11: {
      fontSize: 14,
    },
    h12: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    h13: {
      fontSize: 12,
    },
    body1: {
      fontSize: 22,
      lineHeight: '24px',
      fontWeight: 400,
    },
    body1lanna: {
      fontSize: 22,
      lineHeight: '24px',
      fontWeight: 400,
      fontFamily: 'lanna',
    },
    body2_deprecated: {
      fontSize: 18,
      fontFamily: 'Lanna',
      lineHeight: '22px',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.87)',
    },
    body2: {
      fontSize: 18,
      lineHeight: '22px',
      fontWeight: 400,
    },
    body2lanna: {
      fontSize: 18,
      fontFamily: 'Lanna',
      lineHeight: '22px',
      fontWeight: 400,
    },
    button: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.003em',
    },
    button_deprecated: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.003em',
      textAlign: 'center',
      color: '#00532A',
      cursor: 'pointer',
    },
    subtitle1: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '18px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    helperText: {
      fontWeight: 400,
      fontSize: '16px',
    },
    HeaderTooltips: {
      fontFamily: 'Heavent',
      fontWeight: 500,
      fontSize: '14px',
    },
    MobileMiniDescription: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
    },
    BaseLegacy: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    tagTextBold: {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '18px',
    },
    textLink: {
      fontFamily: 'lanna',
      fontWeight: 400,
      fontSize: '16px',
    },
    tooltipsText: {
      fontFamily: 'lanna',
      fontWeight: 400,
      fontSize: '14px',
    },
    textExtraLarge: {
      fontSize: '48px',
      fontWeight: 'bold',
      lineHeight: 1,
    },
  },
  palette: {
    // from design system
    primaryV2: {
      main: '#00532a',
      light: '#3A8053',
      dark: '#003A00',
    },
    secondaryV2: {
      main: '#1E8764',
      light: '#56B892',
      dark: '#00593A',
    },
    errorV2: {
      main: '#F44336',
      light: '#F88078',
      dark: '#E31B0C',
    },
    success: {
      main: '#4CAF50',
      light: '#7BC67E',
      dark: '#3B873E',
    },
    warning: {
      main: '#FFB547',
      light: '#FFD598',
      dark: '#BE8939',
    },
    infoV2: {
      main: '#666666',
      light: '#9E9E9E',
      dark: '#212121',
    },
    textV2: {
      secondary: 'rgba(0, 0, 0, 0.6)',
      primary: 'rgba(0, 0, 0, 0.87)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    // old or custom color
    primary: {
      main: '#00532a',
      dark: '#174843',
      light: '#9b9b9b',
    },
    primaryCPD: {
      main: '#164843',
    },
    secondaryCPD: {
      main: '#00532A',
    },
    primaryDark: {
      main: '#174843',
      dark: '#113532',
      light: '#9b9b9b',
    },
    statePrimaryOverlay: {
      main: '#00532a',
      pressed: '#00532A1A',
      hover: '#00532A0A',
      selected: '#00532A14',
      disabled: '#FFFFFF',
    },
    stateWhiteOverlay: {
      main: '#00532A',
      hover: '#FFFFFF0F',
      selected: '#FFFFFF14',
      pressed: '#FFFFFF1A',
      disabled: '#0000001F',
    },
    secondary: {
      main: '#1E8764',
      light: '#707070',
    },
    info: {
      main: '#C4C4C4',
      light: '#f5f6f7',
    },
    error: {
      main: red.A400,
      dark: '#d13434',
    },
    errorDark: {
      main: '#F44336',
    },
    background: {
      default: '#fff',
    },
    text: {
      secondary: 'rgba(0, 0, 0, 0.6)',
      primary: 'rgba(0, 0, 0, 0.87)',
      disabled: '#AAAAAA',
    },
    commonText: {
      primary: '#FFFFFF',
      secondary: '#04542b',
    },
    backgroundSuccess: {
      primary: 'rgba(76, 175, 80, 0.2)',
      secondary: '#555555',
    },
    borderSuccess: {
      primary: 'rgba(76, 175, 80, 1)',
      secondary: '#555555',
    },
    border: {
      primary: '#AAAAAA',
    },
    uploadLegacy: {
      primary: '#3155ff',
    },
    icon: {
      main: '#8b8b8b',
    },
    grey: {
      '50': '#23036A',
      '100': '#F5F5F5',
      '200': '#EEEEEE',
      '300': '#E0E0E0',
      '400': '#BDBDBD',
      '500': '#AAAAAA',
      '600': '#676767',
      '700': '#555555',
      '800': '#424242',
      '900': '#212121',
    },
    gradientBgRetail: {
      default: 'linear-gradient(93deg, #3A8053 24.56%, #003A00 93.61%)',
    },
    common: {
      white: '#FFF',
      black: '#000',
    },
    textOnPrimary: {
      primary: '#FFFFFF',
      secondary: '#FFFFFFBD',
      disabled: '#FFFFFF61',
    },
  },
  breakpoints: {
    // phone 320 - 480
    // tablet 481 - 1200
    // desktop 1200+
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1200,
      xl: 2000,
    },
  },

  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          margin: 0,
          color: '#00532a',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: 40,
        },
        outlined: {
          borderRadius: 4,
          border: 'solid 1px rgba(36, 81, 46, 0.8)',
        },
        // TODO: upgrade mui to remove ts ignore
        // @ts-ignore: Unreachable code error
        containedSecondary: ({ theme }: { theme: Theme }) => ({
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primaryV2.main,
          '&:hover': {
            background: `linear-gradient(0deg, rgba(0, 83, 42, 0.04)0%, rgba(0, 83, 42, 0.04)100%), ${theme.palette.common.white}`,
          },
          '&:disabled': {
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.12)0%, rgba(0, 0, 0, 0.12)100%), ${theme.palette.common.white}`,
          },
        }),
        // @ts-ignore: Unreachable code error
        outlinedSecondary: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.commonText?.primary,
          border: `solid 1px ${theme.palette.common.white}`,
          '&:hover': {
            backgroundColor: theme.palette.stateWhiteOverlay.hover,
            border: `solid 1px ${theme.palette.common.white}`,
          },
          '&:disabled': {
            color: theme.palette.textOnPrimary.disabled,
            border: `1px solid ${theme.palette.textOnPrimary.disabled}`,
          },
        }),
        // @ts-ignore: Unreachable code error
        textPrimary: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.primaryV2.main,
        }),
        // @ts-ignore: Unreachable code error
        textSecondary: ({ theme }: { theme: Theme }) => ({
          color: theme.palette.textOnPrimary.primary,
          '&:disabled': {
            color: theme.palette.textOnPrimary.disabled,
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            padding: '0 7px',
          },
          '& .Mui-disabled:not([value=""])': {
            background: '#EEEEEE',
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.6)',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: 'Lanna',
          boxShadow: '0 3px 6px 0 rgb(0 0 0 / 16%)',
        },
        filledSuccess: {
          backgroundColor: '#fff',
          color: '#222222',
          '.MuiAlert-icon': {
            color: '#4CAF50',
          },
        },
        filledError: {
          backgroundColor: '#fff',
          color: '#222222',
          '.MuiAlert-icon': {
            color: 'red',
          },
        },
        action: {
          color: '#AAAAAA',
        },
      },
    },
  },
});

export default theme;

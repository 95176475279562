import { IDegreeHighlight } from '@interfaces/degree';

export interface IDegreeLandingPageData {
  universityLogoImageUrl: string;
  degreeName: string;
  degreeAliasName: string;
  degreeFieldOfStudyName: string;
  majorName: string;
  majorSubject?: string;
  degreeBannerUrl: string;
  aboutDegree: string;
  degreeDetail: string;
  degreeStructureImageUrl: string;
  degreeStructureFileUrl: string;
  minimumDegreeCourses: number;
  degreeMinimumCoursePrice: number;
  degreeMinimumMandatoryCredit: number;
  degreeMinimumMandatoryDetail: string;
  degreeMinimumElectiveCredit: number;
  degreeMinimumElectiveDetail: string;
  degreeIndependentStudiesDetail: string;
  degreeHighlights: IDegreeHighlight[];
  pathToMasterDegreeImageUrl: string;
  pathToMasterDegreeMobileImageUrl: string;
  tuitionFeesImageUrl: string;
  tuitionFeesMobileImageUrl: string;
  degreeStatus: StatusDegreeUniversity;
  courseStructureTotalCreditQuantity: number;
  courseStructureTotalMinimumElectiveCreditQuantity: number;
  courseStructureTotalMinimumMandatoryCreditQuantity: number;

  degreeAlertFlag: {
    id: number;
    title: string;
    description: string;
    startDate: Date;
    endDate: Date;
    buttonText: string;
    buttonLink: string;
  };
}

export interface IUniversityInfo {
  university: {
    id: number;
    nameEn: string;
    nameTh: string;
    slug: string;
    code: string;
    isEnabled: boolean;
    createdAt: Date;
    updatedAt: Date;
  };
}

export interface IUniversityLandingPageData {
  id: number;
  aboutSkilllaneDescription: string;
  aboutSkilllaneImageUrl: string;
  aboutSkilllaneSectionHeading: string;
  annotation: string;
  bannerImageUrl: string;
  bannerImgDefaultSize: string;
  bannerImgResponsiveSize: string;
  bannerResponsiveImageUrl: string;
  logoImageUrl: string;
  blogDescription: string;
  blogSectionHeading: string;
  blogTitle: string;
  blogUrl: string;
  collaborationDescription: string;
  collaborationIllustrationImageUrl: string;
  collaborationIllustrationVideoUrl: string;
  collaborationSectionHeading: string;
  universityLearningInformations: LearningInformation[];
  historyDescription: string;
  historyImageUrl: string;
  historySectionHeading: string;
  isAboutSkilllaneSectionEnabled: boolean;
  isBlogSectionEnabled: boolean;
  isCheckCertificateSectionEnabled: boolean;
  isCollaborationSectionEnabled: boolean;
  isEnabled: boolean;
  isHistorySectionEnabled: boolean;
  isLearnWithUsSectionEnabled: boolean;
  isLearningInformationSectionEnabled: boolean;
  universityLearnWithUsInformations: LearnWithUsInformation[];
  learnWithUsSectionHeading: string;
  learningInformationSectionHeading: string;
  logoImage: string;
  mainColor: string;
  nameEn: string;
  nameTh: string;
  slug: string;
}

export interface LearnWithUsInformation {
  title: string;
  imageUrl: string;
  description: string;
}

export interface LearningInformation {
  title: string;
  imageUrl: string;
  position: number;
  description: string;
}

export interface DegreeUniversity {
  name: string;
  aliasName: string;
  certificateName: string;
  status: StatusDegreeUniversity;
  fieldOfStudyName: string;
  url: string;
  urlImgBanner: string;
  thumbnailImageUrl: string;
  majorName: string;
  majorSubject: string;
  major: {
    name: string;
    majorSubject: string;
  };
}

export enum StatusDegreeUniversity {
  Draft = 'draft',
  EnabledRegister = 'enabled_register',
  ComingSoon = 'coming_soon',
}

export enum DegreeCourseStatus {
  COMING_SOON = 'coming_soon',
  ENABLED = 'enabled',
}

export enum DegreeCourseType {
  MANDATORY = 'Mandatory',
  ELECTIVE = 'Elective',
}

export type UniversityMasterDegree = {
  id: number;
  displayName: string;
  slug: string;
  code: string;
  isEnabled: boolean;
  logoImageUrl: string;
  degrees: {
    id: number;
    displayName: string;
    position: number;
    status: StatusDegreeUniversity;
    friendlyUrl: string;
  }[];
  createdAt: Date;
  updatedAt: Date;
};
